<template>
	<Dialog :dialog="CreateInvoiceDialog" :dialog-width="1200">
		<template v-slot:title>Create Invoice</template>
		<template v-slot:body>
			<v-form
				ref="extendCheckoutForm"
				v-model.trim="formValid"
				lazy-validation
				v-on:submit.stop.prevent="onSubmit"
			>
				<v-row class="mb-3" v-if="internal == true">
					<v-col md="4" class="py-0">
						<label class="input-label required">Customer</label>
					</v-col>
					<v-col md="8" class="py-0">
						<v-autocomplete
							:items="customerData"
							hide-details
							outlined
							placeholder="Contract"
							v-model="invoiceManage.customer"
							item-text="company_name"
							item-value="id"
							v-on:change="getCustomerSalesPeson($event)"
						>
						</v-autocomplete>
					</v-col>
				</v-row>
				<v-row class="mb-3">
					<v-col md="4" class="py-0">
						<label class="input-label required">Contract</label>
					</v-col>
					<v-col md="8" class="py-0">
						<!--  <v-autocomplete
                        :items="customerContract"
                        hide-details
                        outlined
                        placeholder="Contract"
                        v-model="invoiceManage.contract_id"
                        item-text="barcode"
                        item-value="id"
                    >
                    </v-autocomplete> -->
						<v-autocomplete
							:items="customerContract"
							hide-details
							outlined
							placeholder="Contract"
							v-model="invoiceManage.contract_id"
							item-text="title"
							item-value="id"
							:rules="[vrules.required(invoiceManage.contract_id, 'Contract')]"
							:class="{
								required: !invoiceManage.contract_id,
							}"
						>
							<template v-slot:selection="data">
								<v-chip
									small
									v-bind="data.attrs"
									:input-value="data.selected"
									close
									@click="data.select"
									@click:close="remove(data.item)"
								>
									<span>
										{{ data.item.barcode }}
									</span>
									{{ data.item.title }}
								</v-chip>
							</template>
							<template v-slot:item="data">
								<template>
									<v-list-item-content>
										<v-list-item-title>{{ data.item.title }}({{ data.item.barcode }})</v-list-item-title>
									</v-list-item-content>
								</template>
							</template>
						</v-autocomplete>
					</v-col>
				</v-row>
				<v-row class="mb-3">
					<v-col md="4" class="py-0">
						<label for="">Invoice #</label>
					</v-col>
					<v-col md="8" class="py-0">
						<v-text-field hide-details outlined placeholder="Invoice #" v-model="invoiceManage.barcode">
						</v-text-field>
					</v-col>
				</v-row>
				<v-row class="mb-3">
					<v-col md="4" class="py-0">
						<label for="">Actual Date</label>
					</v-col>
					<v-col md="8" class="py-0">
						<DatePicker hide-details outlined custom-class="mt-0" v-model="invoiceManage.actual_date">
						</DatePicker>
					</v-col>
				</v-row>
				<v-row class="mb-3">
					<v-col md="4" class="py-0">
						<label for="">Date</label>
					</v-col>
					<v-col md="8" class="py-0">
						<DatePicker hide-details outlined custom-class="mt-0" v-model="invoiceManage.start_date">
						</DatePicker>
					</v-col>
				</v-row>
				<v-row class="mb-3">
					<v-col md="4" class="py-0">
						<label for="">Payable Amount</label>
					</v-col>
					<v-col md="8" class="py-0">
						<v-text-field
							hide-details
							outlined
							placeholder="Payable Amoun"
							v-model="invoiceManage.payable_amount"
						>
						</v-text-field>
					</v-col>
				</v-row>
				<v-row class="mb-3">
					<v-col md="4" class="py-0">
						<label for="">Paid Amount</label>
					</v-col>
					<v-col md="8" class="py-0">
						<v-text-field
							hide-details
							outlined
							placeholder="Paid Amount"
							v-model="invoiceManage.paid_amount"
						>
						</v-text-field>
					</v-col>
				</v-row>
			</v-form>
		</template>
		<template v-slot:action>
			<v-btn
				class="white--text"
				:loading="pageLoading"
				:disabled="!formValid || pageLoading"
				depressed
				color="blue darken-4"
				tile
				v-on:click="onSubmit()"
			>
				Save
			</v-btn>
			<v-btn depressed tile :disabled="pageLoading" v-on:click="$emit('close', true)"> Close </v-btn>
		</template>
	</Dialog>
</template>
<script>
import { mapGetters } from "vuex";
import Dialog from "@/view/components/Dialog";
import DatePicker from "@/view/components/DatePicker";
import { POST, GET } from "@/core/services/store/request.module";
import MomentJS from "moment-timezone";
import { SET_MESSAGE, SET_ERROR } from "@/core/services/store/common.module";

export default {
	name: "extend-checkout-template",
	title: "Extend Checkout Template",
	props: {
		CreateInvoiceDialog: {
			type: Boolean,
			default: false,
		},
		updatedData: {
			type: Object,
			default() {
				return new Object();
			},
		},
		relatedId: {
			type: Number,
			default: 0,
		},
		internal: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			formValid: true,
			pageLoading: false,
			customerContract: [],
			customerData: [],
			invoiceManage: {
				barcode: null,
				actual_date: null,
				start_date: null,
				contract_id: 0,
				customer: 0,
				payable_amount: 0,
				paid_amount: 0,
			},
		};
	},
	watch: {
		updatedData: {
			deep: true,
			immediate: true,
			handler(param) {
				this.init(param);
			},
		},
	},
	components: {
		Dialog,
		DatePicker,
	},

	methods: {
		getCustomerSalesPeson(data) {
			const _this = this;
			if (data > 0) {
				this.relatedId = data;
			}

			_this.$store
				.dispatch(GET, {
					url: `customer-sales/${this.relatedId}`,
				})
				.then((data) => {
					console.log(data);
					/* _this.salesPserson = data.attendie[0].attendies; */
					_this.customerContract = data.customer_contract;
					/* _this.getCustomerData() */
				})
				.catch((error) => {
					console.log({
						error,
					});
				})
				.finally(() => {
					_this.pageLoading = false;
				});
		},
		getCustomerData() {
			this.$store
				.dispatch(GET, {
					url: `customer`,
				})
				.then((data) => {
					this.customerData = data.tbody;
					this.getCustomerSalesPeson();
				})
				.catch((error) => {
					console.log({
						error,
					});
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		init(data) {
			this.alldata = data;
			this.invoiceManage = {
				id: data.id,
				actual_date: data.actual_date
					? MomentJS(data.actual_date, "YYYY-MM-DD HH:II:SS").format("YYYY-MM-DD")
					: null,
				barcode: data.barcode ? data.barcode : null,
				payable_amount: data.payable_amount ? data.payable_amount : null,
				paid_amount: data.paid_amount ? data.paid_amount : null,
			};
		},
		onSubmit() {
			const _this = this;

			const formErrors = _this.validateForm(_this.$refs.extendCheckoutForm);
			if (formErrors.length) {
				_this.$store.commit(SET_ERROR, _this.errors.concat(formErrors));
				return false;
			}

			if (!_this.$refs.extendCheckoutForm.validate()) {
				return false;
			}

			this.pageLoading = true;
			this.invoiceManage.customer = this.relatedId;
			console.log(this.invoiceManage);
			this.$store
				.dispatch(POST, { url: `invoice`, data: this.invoiceManage })
				.then(() => {
					this.$store.commit(SET_MESSAGE, [
						{ model: true, message: "Success ! Invoice Create successfully." },
						this.$emit("success", true),
					]);

					this.$refs.form.reset();
					this.$emit("refressdata", true);
				})
				.catch((error) => {
					console.log({ error });
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},

		getSettingbarcode() {
			this.$store
				.dispatch(GET, { url: `setting/invoice`, data: this.invoiceManage })
				.then((data) => {
					this.invoiceManage.barcode = data.prefix;
				})
				.catch((error) => {
					console.log({ error });
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
	},

	computed: {
		...mapGetters(["errors"]),
	},
	mounted() {
		if (this.relatedId > 0) {
			this.getCustomerSalesPeson();
		}
		this.getCustomerData();
	},
};
</script>
