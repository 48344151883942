<template>
	<v-sheet class="main-detail recurring-detail">
		<div class="">
			<v-layout class="align-center light-blue-bg px-4 py-2 flex-wrap">
				<v-flex md12>
					<div class="d-flex">
						<v-menu offset-y right bottom content-class="elevation-3" rounded="0">
							<template v-slot:activator="{ on, attrs }">
								<p class="sub-heading m-0" v-on="on" v-bind="attrs">
									All Recurring
									<v-icon>mdi-menu-down</v-icon>
								</p>
							</template>
							<v-list>
								<v-list-item
									v-for="(status, index) in recurringStatus"
									:key="index"
									v-on:click="statusFilter(status.value)"
								>
									<v-list-item-title class="d-flex align-items-center">
										{{ status.text }} <span class="circle" :class="status.status_color"></span>
										<v-spacer></v-spacer>
										<div class="cm-budge white--text" :class="status.status_color">
											{{ status.status_count }}
										</div>
									</v-list-item-title>
								</v-list-item>
							</v-list>
						</v-menu>

						<!-- <div class="d-flex justify-content-end pr-1 ml-4">
							<div class="mr-2 fw-600 my-auto">Filter By</div>
							<div class="filterTagSelect">
								<v-autocomplete
									v-model="filter_tag"
									:items="tagItems"
									:menu-props="{ bottom: true, offsetY: true }"
									prepend-inner-icon="mdi-tag"
									outlined
									hide-details
									class="filterSelect"
									content-class="filterSelectContent"
								>
								</v-autocomplete>
							</div>
							<div class="filterTagSelect ml-3">
								<v-autocomplete
									v-model="filter_user"
									:items="userItems"
									:menu-props="{ bottom: true, offsetY: true }"
									prepend-inner-icon="mdi-filter"
									outlined
									hide-details
									class="filterSelect"
									content-class="filterSelectContent"
								>
								</v-autocomplete>
							</div>
							<div class="filterTagSelect search-field ml-3">
								<v-text-field
									v-model="filter_customer"
									outlined
									placeholder="Search..."
									hide-details
									class="filterSelect"
								>
								</v-text-field>
							</div>
						</div> -->
						<template v-if="singleRecurring && singleRecurring.renewed == 1">
							<v-chip class="px-2 mr-2" label color="cyan white--text" x-small>Renewed</v-chip>
						</template>
						<template v-if="singleRecurring && singleRecurring.cancelled == 1">
							<v-chip class="px-2" label color="red white--text" x-small>Cancelled</v-chip>
						</template>
						<v-spacer></v-spacer>
						<!-- <v-menu offset-y left bottom content-class="elevation-3" rounded="0">
							<template v-slot:activator="{ on, attrs }">
								<div v-on="on" v-bind="attrs" class="mr-2">
									<v-chip link color="green white--text" class="d-block rounded pr-1" label>
										Paid
										<v-icon class="ml-2">mdi-menu-down</v-icon>
									</v-chip>
								</div>
							</template>
							<v-list v-for="(i, index) in 5" :key="index" class="py-0">
								<v-list-item class="px-0 d-block py-1 px-2">
									<v-chip link :color="`${status[index].color} white--text`" class="d-block" label>{{
										status[index].name
									}}</v-chip>
								</v-list-item>
							</v-list>
						</v-menu> -->
						<!-- <v-btn
							class="white--text mr-2"
							depressed
							color="blue darken-4" 
							tile 
							v-on:click="updateStatus('renewed')">
							Renew
					    </v-btn>
						<v-btn 
							class="white--text mr-2"
							depressed
							color="red darken-4" 
							tile 
							v-on:click="updateStatus('cancelled')">
							Cancel
					    </v-btn>-->
						<v-btn depressed tile class="my-auto" v-on:click="goBack()">
							<v-icon class="mr-1">mdi-keyboard-backspace</v-icon>
							back
						</v-btn>
					</div>
				</v-flex>
			</v-layout>
			<v-layout class="section-main-content">
				<div class="listing-sidebar border-right">
					<div class="side-listing listing-sidebar-list">
						<div class="listing-innerlist">
							<div
								class="list-items"
								v-for="(data, index) in statusData"
								:key="index"
								:class="data.id == recurringId ? 'active' : ''"
								v-on:click="tabDetails(data.id)"
							>
								<div class="ellipsis pr-6 mb-1">
									<v-icon size="20" left>mdi-account</v-icon>
									<span class="fw-500 primary--text" v-if="data && data.contact_person">{{
										data.contact_person
									}}</span>
								</div>
								<div class="ellipsis pr-6 mb-1">
									<v-icon size="20" left>mdi-domain</v-icon>
									<span class="fw-500 primary--text" v-if="data && data.company_name">{{
										data.company_name
									}}</span>
								</div>
								<div class="d-flex justify-content-between">
									<div class="ellipsis pr-6 mb-1">
										<v-icon size="20" left>mdi-phone</v-icon>
										<span class="fw-500 primary--text" v-if="data && data.primary_number">{{
											data.primary_number
										}}</span>
									</div>
								</div>
								<div class="d-flex">
									<div class="ellipsis pr-6 mb-1">
										<v-icon size="20" left>mdi-email</v-icon>
										<span class="fw-500 primary--text" v-if="data && data.primary_email">{{
											data.primary_email
										}}</span>
									</div>
									<v-spacer></v-spacer>
								</div>
								<div class="d-flex">
									<div class="ellipsis pr-6 mb-1">
										<v-icon size="20" left>mdi-web</v-icon>
										<span class="fw-500 primary--text" v-if="data && data.web_url">{{ data.web_url }}</span>
									</div>
									<v-spacer></v-spacer>
								</div>
								<!-- <div class="d-flex justify-content-between">
									<v-tooltip top content-class="custom-top-tooltip">
										<template v-slot:activator="{ on, attr }">
											<div class="ellipsis mb-1" v-on="on" v-bind="attr">
												<v-icon size="20" left color="primary">mdi-web</v-icon>
												<span class="fw-500 primary--text">{{ data.web_url }}</span>
											</div>
										</template>
										<span>Contract Start</span>
									</v-tooltip>
									<v-tooltip top content-class="custom-top-tooltip">
										<template v-slot:activator="{ on, attr }">
											<div class="ellipsis mb-1" v-on="on" v-bind="attr">
												<v-icon size="20" left color="red">mdi-calendar</v-icon>
												<span class="fw-500 red--text">{{ data.end_date }}</span>
											</div>
										</template>
										<span>Contract End</span>
									</v-tooltip>
								</div> -->
							</div>
						</div>
					</div>
					<!-- customer details -->

					<!-- customer details end -->
				</div>
				<div class="details-body grey lighten-4">
					<div class="p-sticky" style="z-index: 1">
						<v-layout
							class="pt-2 pb-0 light-blue-bg flex-wrap"
							style="position: sticky; top: 0; z-index: 2"
						>
							<v-flex md12 class="px-4">
								<div class="d-flex">
									<p class="my-auto font-level-3-bold">
										<span class="fw-600 mr-2" v-if="singleRecurring && singleRecurring.company_name">{{
											singleRecurring.company_name
										}}</span>
									</p>
									<v-spacer></v-spacer>
								</div>
							</v-flex>
							<v-flex md12 class="d-flex flex-wrap px-4">
								<div class="w-25">
									<div class="mt-2">
										<span class="fw-600">Company : </span>
										<span class="fw-500" v-if="singleRecurring && singleRecurring.company_name">
											{{ singleRecurring.company_name }}</span
										>
									</div>
									<div class="mt-2 d-flex">
										<span class="fw-600 mr-2">Contact Person </span>
										<span class="fw-500" v-if="singleRecurring && singleRecurring.contact_person">
											{{ singleRecurring.contact_person }}</span
										>
									</div>
								</div>
								<div class="w-25">
									<div class="mt-2">
										<span class="fw-600">Phone : </span>
										<span class="fw-500">
											<span
												class="fw-500 primary--text"
												v-if="singleRecurring && singleRecurring.primary_number"
												>{{ singleRecurring.primary_number }}</span
											>
										</span>
									</div>
									<div class="mt-2">
										<span class="fw-600"> Email : </span>
										<span class="fw-500">
											<span
												class="fw-500 primary--text"
												v-if="singleRecurring && singleRecurring.primary_email"
												>{{ singleRecurring.primary_email }}</span
											>
										</span>
									</div>
								</div>
								<div class="w-25">
									<div class="mt-2">
										<span class="fw-600">Designation : </span>
										<span>
											<v-chip
												class=""
												label
												color="green white--text"
												x-small
												v-if="singleRecurring && singleRecurring.designation"
											>
												{{ singleRecurring.designation }}
											</v-chip>
										</span>
									</div>
									<div class="mt-2">
										<span class="fw-600 mr-2">Sales Date : </span>
										<span v-if="singleRecurring && singleRecurring.sales_date">
											{{ formatDate(singleRecurring.sales_date) }}</span
										>
									</div>
								</div>
								<div class="w-25">
									<div class="mt-2">
										<span class="fw-600">kws : </span>
										<span>
											<v-chip class="px-2" label color="orange white--text" x-small>
												{{ singleRecurring.kws }}
											</v-chip>
										</span>
									</div>
									<div class="mt-2">
										<span class="fw-600 mr-2">Payment Terms : </span>
										<template v-if="singleRecurring && singleRecurring.payment_terms == 'yearly'">
											<v-chip class="px-2" label color="cyan white--text" x-small>Yearly</v-chip>
										</template>
										<template v-else-if="singleRecurring && singleRecurring.payment_terms == 'half_yearly'">
											<v-chip class="px-2" label color="cyan white--text" x-small>Half Yearly</v-chip>
										</template>
										<template v-else-if="singleRecurring && singleRecurring.payment_terms == 'monthly'">
											<v-chip class="px-2" label color="cyan white--text" x-small>Monthly</v-chip>
										</template>
										<template v-else-if="singleRecurring && singleRecurring.payment_terms == 'quarterly'">
											<v-chip class="px-2" label color="cyan white--text" x-small>Quarterly</v-chip>
										</template>
									</div>
								</div>
							</v-flex>
							<v-flex md12 class="px-4 mt-2 grey lighten-4">
								<v-tabs
									v-model="dataTab"
									background-color="transparent"
									color="blue"
									class="custom-tab-transparent border-bottom w-100"
									active-class="blue darken-4 text-white"
									hide-slider
								>
									<v-tab href="#overview"><v-icon small left>mdi-information-outline</v-icon>Overview</v-tab>
									<!-- <v-tab href="#invoice"><v-icon small left>mdi-information-outline</v-icon>Invoice</v-tab>
									<v-tab href="#ranking"><v-icon small left>mdi-information-outline</v-icon>KPI</v-tab>
									<v-tab href="#notes"><v-icon small left>mdi-note</v-icon>Notes</v-tab> -->
								</v-tabs>
							</v-flex>
						</v-layout>

						<div class="pt-2">
							<v-tabs-items v-model="dataTab" class="transparent">
								<v-tab-item value="overview">
									<div class="flaot-wrapper">
										<v-col cols="12" lg="6">
											<div class="detail-card bg-white">
												<div class="card--header d-flex align-items-center pa-2 border-bottom">
													<div class="font-level-3-bold">Address Details</div>
													<v-spacer></v-spacer>
												</div>
												<div class="card--body d-flex align-items-center pa-2">
													<table class="w-100">
														<tr>
															<td class="text-muted fw-500 px-3 py-2">Unit No</td>
															<td class="fw-600 px-3 py-1" v-if="singleRecurring && singleRecurring.unit_number">
																{{ singleRecurring.unit_number }}
															</td>
														</tr>
														<tr>
															<td class="text-muted fw-500 px-3 py-2">Address 1</td>
															<td
																class="fw-600 px-3 py-1"
																v-if="singleRecurring && singleRecurring.address_line_1"
															>
																{{ singleRecurring.address_line_1 }}
															</td>
														</tr>
														<tr>
															<td class="text-muted fw-500 px-3 py-2">Address 2</td>
															<td
																class="fw-600 px-3 py-1"
																v-if="singleRecurring && singleRecurring.address_line_2"
															>
																{{ singleRecurring.address_line_2 }}
															</td>
														</tr>
														<tr>
															<td class="text-muted fw-500 px-3 py-2">Postal Code</td>
															<td class="fw-600 px-3 py-1" v-if="singleRecurring && singleRecurring.postal_code">
																{{ singleRecurring.postal_code }}
															</td>
														</tr>
														<tr>
															<td class="text-muted fw-500 px-3 py-2">Country</td>
															<td class="fw-600 px-3 py-1" v-if="singleRecurring && singleRecurring.country">
																{{ singleRecurring.country }}
															</td>
														</tr>
														<tr>
															<td class="text-muted fw-500 px-3 py-2">Currency</td>
															<td class="fw-600 px-3 py-1" v-if="singleRecurring && singleRecurring.currency">
																{{ singleRecurring.currency }}
															</td>
														</tr>
													</table>
												</div>
											</div>
										</v-col>
										<v-col md="12" lg="6">
											<div class="detail-card bg-white">
												<div class="card--header d-flex align-items-center pa-2 border-bottom">
													<div class="font-level-3-bold">Project Details</div>
													<v-spacer></v-spacer>
												</div>
												<div class="card--body d-flex align-items-center pa-2">
													<table class="w-100">
														<tr>
															<td class="text-muted fw-500 px-3 py-2">Project Type</td>
															<td class="fw-600 px-3 py-1" style="display: inline">
																<div v-for="(project, index) in project_types" :key="index">
																	<v-chip
																		color="cyan white--text"
																		label
																		class="mr-2 mb-1 text-uppercase"
																		v-if="project"
																		>{{ project }}</v-chip
																	>
																</div>
																<!-- <v-chip color="cyan white--text" label class="mr-2 mb-1">Web</v-chip>
																<v-chip color="cyan white--text" label class="mb-1">domain</v-chip> -->
															</td>
														</tr>
														<tr>
															<td class="text-muted fw-500 px-3 py-2">KWS</td>
															<td class="fw-600 px-3 py-1" v-if="singleRecurring && singleRecurring.kws">
																{{ singleRecurring.kws }}
															</td>
														</tr>
														<tr>
															<td class="text-muted fw-500 px-3 py-2">Website</td>
															<td class="fw-600 px-3 py-1" v-if="singleRecurring && singleRecurring.web_url">
																{{ singleRecurring.web_url }}
															</td>
														</tr>
														<tr>
															<td class="text-muted fw-500 px-3 py-2">Main Contract</td>
															<td class="fw-600 px-3 py-1" v-if="singleRecurring && singleRecurring.web_url">
																{{ singleRecurring.main_contact }}
															</td>
														</tr>
														<tr>
															<td class="text-muted fw-500 px-3 py-2">Offered Month</td>
															<td class="fw-600 px-3 py-1" v-if="singleRecurring && singleRecurring.offered_month">
																{{ singleRecurring.offered_month }} Months
															</td>
														</tr>
													</table>
												</div>
											</div>
										</v-col>
										<v-col md="12" lg="6">
											<div class="detail-card bg-white">
												<div class="card--header d-flex align-items-center pa-2 border-bottom">
													<div class="font-level-3-bold">Timeframe & Sales Details</div>
													<v-spacer></v-spacer>
												</div>
												<div class="card--body d-flex align-items-center pa-2">
													<table class="w-100">
														<tr>
															<td class="text-muted fw-500 px-3 py-2">Contract Start Date</td>
															<td
																class="fw-600 px-3 py-1"
																v-if="singleRecurring && singleRecurring.contact_start_date"
															>
																{{ formatDate(singleRecurring.contact_start_date) }}
															</td>
														</tr>
														<tr>
															<td class="text-muted fw-500 px-3 py-2">Contract End Date</td>
															<td
																class="fw-600 px-3 py-1"
																v-if="singleRecurring && singleRecurring.contact_end_date"
															>
																{{ formatDate(singleRecurring.contact_end_date) }}
															</td>
														</tr>
														<tr>
															<td class="text-muted fw-500 px-3 py-2">Sales Date</td>
															<td class="fw-600 px-3 py-1" v-if="singleRecurring && singleRecurring.sales_date">
																{{ formatDate(singleRecurring.sales_date) }}
															</td>
														</tr>
														<tr>
															<td class="text-muted fw-500 px-3 py-2">Invoice Date</td>
															<td class="fw-600 px-3 py-1" v-if="singleRecurring && singleRecurring.invoice_date">
																{{ formatDate(singleRecurring.invoice_date) }}
															</td>
														</tr>
														<tr>
															<td class="text-muted fw-500 px-3 py-2">Invoice Number</td>
															<td
																class="fw-600 px-3 py-1"
																v-if="singleRecurring && singleRecurring.invoice_number"
															>
																{{ singleRecurring.invoice_number }}
															</td>
														</tr>
														<tr>
															<td class="text-muted fw-500 px-3 py-2">Payment Terms</td>
															<td class="fw-600 px-3 py-1">
																<template v-if="singleRecurring && singleRecurring.payment_terms == 'yearly'">
																	<v-chip label color="cyan white--text">Yearly</v-chip>
																</template>
																<template
																	v-else-if="singleRecurring && singleRecurring.payment_terms == 'half_yearly'"
																>
																	<v-chip label color="cyan white--text">Half Yearly</v-chip>
																</template>
																<template v-else-if="singleRecurring && singleRecurring.payment_terms == 'monthly'">
																	<v-chip label color="cyan white--text">Monthly</v-chip>
																</template>
																<template
																	v-else-if="singleRecurring && singleRecurring.payment_terms == 'quarterly'"
																>
																	<v-chip label color="cyan white--text">Quarterly</v-chip>
																</template>
															</td>
														</tr>
													</table>
												</div>
											</div>
										</v-col>
										<v-col md="12" lg="6">
											<div class="detail-card bg-white">
												<div class="card--header d-flex align-items-center pa-2 border-bottom">
													<div class="font-level-3-bold">Sales Person Details</div>
													<v-spacer></v-spacer>
												</div>
												<div class="card--body d-flex align-items-center pa-2">
													<table class="w-100">
														<tr>
															<td class="text-muted fw-500 px-3 py-2">First Name</td>
															<td class="fw-600 px-3 py-1" v-if="userData && userData.first_name">
																{{ userData.first_name }}
															</td>
														</tr>
														<tr>
															<td class="text-muted fw-500 px-3 py-2">Last Name</td>
															<td class="fw-600 px-3 py-1" v-if="userData && userData.last_name">
																{{ userData.last_name }}
															</td>
														</tr>
														<tr>
															<td class="text-muted fw-500 px-3 py-2">Email</td>
															<td class="fw-600 px-3 py-1" v-if="userData && userData.email">
																{{ userData.email }}
															</td>
														</tr>
														<tr>
															<td class="text-muted fw-500 px-3 py-2">Phone Number</td>
															<td class="fw-600 px-3 py-1" v-if="userData && userData.phone_number">
																{{ userData.phone_number }}
															</td>
														</tr>
														<tr>
															<td class="text-muted fw-500 px-3 py-2">Department</td>
															<td class="fw-600 px-3 py-1" v-if="userData && userData.department">
																{{ userData.department }}
															</td>
														</tr>
													</table>
												</div>
											</div>
										</v-col>
									</div>
								</v-tab-item>
								<v-tab-item value="invoice">
									<RecurringInvoiceTab></RecurringInvoiceTab>
								</v-tab-item>
								<v-tab-item value="notes">
									<div class="white">
										<NotesTab :open_details="true"></NotesTab>
									</div>
								</v-tab-item>
								<v-tab-item value="ranking">
									<div class="white">
										<v-layout class="w-100 px-3 pt-2 align-center">
											<h4>KPI</h4>
											<v-spacer></v-spacer>
											<!-- <v-flex class="d-flex justify-content-end mr-1">
												<v-text-field
													hide-details
													outlined
													v-model="search_query"
													class="mr-2"
													style="max-width: 250px"
													placeholder="Search..."
												></v-text-field>
												<v-btn depressed color="blue darken-4" class="text-white mx-0" tile min-height="30">
													<v-icon>mdi-magnify</v-icon>
												</v-btn>
											</v-flex> -->
											<v-btn depressed tile color="blue darken-4" class="text-white" min-height="30">
												<v-icon> mdi-sync </v-icon>
											</v-btn>
										</v-layout>
										<!-- <v-col cols="12" class="v-data-table bt-table theme--light listing-height">
											<div class="v-data-table__wrapper h-100">
												<table class="w-100">
													<thead>
														<tr>
															<th width="40">SR.No.</th>
															<th>Keyword</th>
															<th>Current Rank</th>
															<th>Top 50</th>
															<th>Top 20</th>
															<th>Top 10</th>
															<th>Top 5</th>
															<th>Top 3</th>
															<th>Top 2</th>
															<th>Top 1</th>
														</tr>
													</thead>
													<tbody>
														<tr v-for="n in 5" :key="n">
															<td width="40">{{ n }}</td>
															<td>Commericial renovation singapure</td>
															<td>Not In SERP</td>
															<td>6 Month</td>
															<td>9 Month</td>
															<td>12 Month</td>
															<td>N/A</td>
															<td>N/A</td>
															<td>N/A</td>
															<td>N/A</td>
														</tr>
													</tbody>
												</table>
											</div>
										</v-col> -->
									</div>
								</v-tab-item>
							</v-tabs-items>
						</div>
					</div>
				</div>
			</v-layout>
		</div>
		<Dialog :dialog="open_upload_dialog">
			<template v-slot:title> Upload KPI </template>
			<template v-slot:body>
				<template v-for="(data, index) in fileList">
					<v-row class="mb-3 px-4" :key="index">
						<v-col lg="10" cols="10" class="my-auto py-0 d-flex align-items-center">
							<v-text-field
								hide-details
								outlined
								v-model="data.name"
								:prepend-inner-icon="data.icon"
								:suffix="data.extension"
							>
							</v-text-field>
							<v-btn
								tile
								color="transparent"
								depressed
								class="border"
								style="height: 30px !important; border: 1px solid #00000061 !important"
								v-on:click="selectFile(index)"
							>
								<v-icon>mdi-attachment</v-icon>
							</v-btn>
							<span class="fw-600 ml-2 primary--text d-block" style="min-width: 100px">{{
								data.size
							}}</span>
						</v-col>
						<v-col lg="2" cols="2" class="my-auto py-0">
							<v-tooltip top content-class="custom-top-tooltip">
								<template v-slot:activator="{ on, attrs }">
									<span v-on="on" v-bind="attrs">
										<v-btn tile icon depressed color="red white--text" v-on:click="remove_file(index)">
											<v-icon>mdi-delete</v-icon>
										</v-btn>
									</span>
								</template>
								<span>Remove File</span>
							</v-tooltip>
						</v-col>
					</v-row>
				</template>
				<v-row v-if="fileList.length < 1" class="mb-3 px-4">
					<v-col
						v-if="fileList.length < 1"
						md="12"
						class="py-10 text-center grey--text pointer"
						style="border: 2px dashed #ccc"
						v-on:click="selectFile(-1)"
					>
						<h4 class="fw-400">Upload File Here...</h4>
					</v-col>
				</v-row>
				<v-row v-else class="mb-3 px-4">
					<v-col lg="6" class="my-auto py-0">
						<v-btn tile depressed color="blue darken-4 white--text" v-on:click="addMoreFile()">
							<v-icon>mdi-plus</v-icon>
							Add More
						</v-btn>
					</v-col>
				</v-row>
				<v-file-input ref="uploadFile" class="d-none" v-on:change="uploadFile($event)"></v-file-input>
			</template>
			<template v-slot:action>
				<v-btn color="blue darken-4 white--text" tile depressed class="mr-2"> Save </v-btn>
				<v-btn tile depressed v-on:click="closeDialog()"> Cancel </v-btn>
			</template>
		</Dialog>
	</v-sheet>
</template>
<script>
import RecurringInvoiceTab from "@/view/module/components/RecurringInvoiceTab.vue";
import Dialog from "@/view/components/Dialog";
import NotesTab from "@/view/pages/leads/components/NotesTab3";
import { GET, QUERY } from "@/core/services/store/request.module";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
//import { SET_MESSAGE } from "@/core/services/store/common.module";

export default {
	name: "Recurring-details",
	data() {
		return {
			open_upload_dialog: false,
			dataTab: "overview",
			singleRecurring: [],
			project_types: [],
			statusData: [],
			recurringStatus: [],
			fileIndex: -1,
			tagItems: ["Software", "SEO"],
			filter_tag: "Software",
			userItems: ["Everyone"],
			filter_user: "Everyone",
			filter_customer: "",
			fileList: [],
			userData: [],
			status: [
				{ color: "blue", name: "Raised", count: "2" },
				{ color: "green", name: "Paid", count: "1" },
				{ color: "red", name: "Unpaid", count: "3" },
				{ color: "cyan", name: "Postponed", count: "4" },
				{ color: "red", name: "Cancelled", count: "2" },
			],
		};
	},
	components: {
		Dialog,
		NotesTab,
		RecurringInvoiceTab,
	},
	methods: {
		/* updateStatus(status){
			this.$store
				.dispatch(PATCH, {
					url: `recurring-renew/${this.recurringId}/${status}`,
				})
				.then(() => {
					
					this.getRecurring();
					this.$store.commit(SET_MESSAGE, [
						{ model: true, message: "Recurring Update Successfully." },
					]);
				})
				.catch((error) => {
					console.log({
						error,
					});
				});
		}, */
		statusFilter(value) {
			this.$store
				.dispatch(QUERY, { url: `recurring-renew`, data: { status: value } })
				.then((data) => {
					this.statusData = data.tbody;
				})
				.catch((error) => {
					console.log({ error });
				});
		},
		getRecurring() {
			this.$store
				.dispatch(GET, {
					url: `single-renew/${this.recurringId}`,
				})
				.then((data) => {
					this.singleRecurring = data.recurrings;
					this.project_types = data.project_types;
					this.userData = data.userData;
				})
				.catch((error) => {
					console.log({
						error,
					});
				});
		},
		getRecurringSetting() {
			this.$store
				.dispatch(GET, {
					url: `recurring-setting`,
				})
				.then((data) => {
					this.recurringStatus = data.status;
				})
				.catch((error) => {
					console.log({
						error,
					});
				});
		},
		tabDetails(id) {
			this.$router.push({
				params: { id },
			});
			this.recurringId = id;
			this.getRecurring();
		},
		pageTitle() {
			return "Renew Details";
		},
	},
	mounted() {
		/* this.getSingleCustomer();
		this.getCustomerLists();*/
		this.getRecurringSetting();
		this.getRecurring();
		this.statusFilter();

		this.$store.dispatch(SET_BREADCRUMB, [
			{ text: "Dashboard", disabled: false, href: "/dashboard" },
			{ text: "Renew", disabled: false, href: "project-to-be-renewed" },
			{ text: "Detail", disabled: true },
		]);
	},
	beforeMount() {
		this.recurringId = this.$route.params.id;
	},
};
</script>
